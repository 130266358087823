import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import {
    AuthService,
    CompleteSignUpDto,
    GetOpenidProvidersDto,
    HttpHeaderNames,
    IdpOauth2AuthorizeAction,
    IdpOauth2GrantType,
    IdpOauth2LoginCodeChallengeMethod,
    IdpOauth2LoginResponseType,
    IdpOauth2LoginScope,
    LinkOpenIDDto,
    Oauth2AuthorizeQueryDto,
    Oauth2CreateTokenDto,
    Oauth2RevokeTokenDto,
    OobRequestType,
    OpenIdAuthorizeQueryDto,
    OpenIdProvider,
    OpenIdTokenDto,
    OpenIdTokenResultDto,
    ResetPasswordDto,
    SendOobCodeDto,
    SendOobCodeResultDto,
    SignInResultDto,
    SignInWithEmailDto,
    SignInWithOpenIDDto,
    SignInWithPasswordDto,
    SignupUserDto,
    SignupUserResultDto,
    SignupWithOpenIDDto,
} from '@icp/interfaces';
import { catchError, firstValueFrom, from, map, Observable, of, shareReplay, switchMap, tap, throwError } from 'rxjs';

import { retryWithBackoff } from '../rxjs-operators';
import { getCodeChallenge, randomString } from './util';

type OobCodeData = SendOobCodeDto & SendOobCodeResultDto & Partial<Pick<SignupUserResultDto, 'codeLength' | 'id'>>;

export interface AuthSession {
    accessToken: string;
    refreshToken: string;
    clientId: string;
}

export interface AccessToken {
    aud: string;
    azp: string;
    exp: number;
    iat: number;
    iss: string;
    scope: string;
    sub: string;
    token_use: string;
}

export interface DecodedAuthSession extends AuthSession {
    decodedAccessToken: AccessToken;
}

export interface IdpServiceConfig {
    serverUrl: string;
    clientId: string | null;
    storage: new () => IdpStorage;
}

interface AuthorizeParams {
    state: string;
    nonce: string;
    redirect_uri: string;
}

const IDP_SERVICE_CONFIG = new InjectionToken<IdpServiceConfig>('idp-service-config');

export function provideIdpServiceConfig(config: IdpServiceConfig) {
    return makeEnvironmentProviders([{ provide: IDP_SERVICE_CONFIG, useValue: config }]);
}

export interface IdpStorage {
    getItem(key: string): Promise<string | null>;

    setItem(key: string, value: string): Promise<void>;

    removeItem(key: string): Promise<void>;
}

export class DefaultIdpStorage implements IdpStorage {
    getItem(key: string): Promise<string | null> {
        return Promise.resolve(localStorage.getItem(key));
    }

    setItem(key: string, value: string): Promise<void> {
        localStorage.setItem(key, value);
        return Promise.resolve();
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
        return Promise.resolve();
    }
}

@Injectable({ providedIn: 'root' })
export class IdpService {
    private config = inject(IDP_SERVICE_CONFIG);
    private http = inject(HttpClient);
    private isRefreshingAccessToken = false;
    private refreshTokenObservable: Observable<AuthSession> | null = null;
    private signInData: { email: string; password: string | null } | null = null;
    private storage = new this.config.storage();
    private authService = inject(AuthService);

    constructor() {
        this.authService.configuration.basePath = this.config.serverUrl;
        this.authService.configuration.withCredentials = true;
    }

    /**
     * Validates if the auth token is still valid.
     * Refreshes the token if it has expired using the refresh token.
     */
    getCurrentSession(): Observable<AuthSession | null> {
        return from(this.getSessionFromStorage()).pipe(
            switchMap((session) => {
                if (!session) {
                    return of(null);
                }
                const now = new Date();
                // Refresh 1 minute too early
                if (session.decodedAccessToken.exp * 1000 < now.getTime() + 60000) {
                    return this.refreshToken({
                        client_id: this.config.clientId!,
                        grant_type: IdpOauth2GrantType.REFRESH_TOKEN,
                        refresh_token: session.refreshToken,
                    }).pipe(
                        catchError((err) => {
                            if (err instanceof HttpErrorResponse) {
                                if (err.status === HttpStatusCode.GatewayTimeout || err.status === 0) {
                                    // trigger retry
                                    return throwError(() => err);
                                }
                            }
                            // if an error is still throw, let's just clear all
                            // Could happen when our refresh token is revoked server side
                            return from(this.deleteSessionFromStorage()).pipe(map(() => null));
                        }),
                        retryWithBackoff(10, 500),
                    );
                }
                return of(session);
            }),
        );
    }

    getSignInData() {
        return this.signInData;
    }

    async signUp(data: Omit<SignupUserDto, 'clientId'>): Promise<SignupUserResultDto> {
        this.signInData = { email: data.email, password: data.password! };
        const result = await firstValueFrom(this.authService.signUp({ ...data, clientId: this.config.clientId! }));
        await this.storeOobCodeData({
            url: data.url!,
            email: data.email,
            length: result.codeLength,
            id: result.id,
            clientId: this.config.clientId!,
            requestType: OobRequestType.EMAIL_SIGNIN,
        });
        return result;
    }

    async sendOobCode(data: Omit<SendOobCodeDto, 'clientId'>): Promise<SendOobCodeResultDto> {
        const postData = { ...data, clientId: this.config.clientId! };
        const result = await firstValueFrom(this.authService.sendOobCode(postData));
        await this.storeOobCodeData({ ...postData, ...result });
        return result;
    }

    async completeSignup(data: Omit<CompleteSignUpDto, 'clientId'>): Promise<AuthSession | null> {
        this.signInData = { email: data.email, password: this.signInData?.password ?? null };
        const postData = { ...data, clientId: this.config.clientId! };
        const result: SignInResultDto | undefined = await firstValueFrom(this.authService.completeSignUp(postData));
        await this.deleteOobCodeDataFromStorage();
        if (result) {
            return this.storeSession(result);
        }
        return null;
    }

    async signInWithEmail(oobCode: string): Promise<AuthSession> {
        const data = await this.getOobCodeData();
        if (!data) {
            throw new Error('No oob code data found');
        }
        const payload: SignInWithEmailDto = {
            email: data.email,
            clientId: data.clientId,
            oobCode,
        };
        const result = await firstValueFrom(this.authService.signInWithEmail(payload));
        await this.deleteOobCodeDataFromStorage();
        return this.storeSession(result);
    }

    async signInWithPassword(data: Omit<SignInWithPasswordDto, 'clientId'>): Promise<null | AuthSession> {
        this.signInData = null;
        const response = await firstValueFrom(
            this.authService.signInWithPassword({ ...data, clientId: this.config.clientId! }, 'response'),
        );
        if (response.status === HttpStatusCode.NoContent) {
            return null;
        }
        return this.storeSession(response.body as SignInResultDto);
    }

    async signInWithJWT(data: Omit<SignInWithOpenIDDto, 'clientId'>): Promise<AuthSession> {
        this.signInData = null;
        const result = await firstValueFrom(
            this.authService.signInWithOpenID({ ...data, clientId: this.config.clientId! }),
        );
        return await this.storeSession(result);
    }

    async signUpWithJWT(data: Omit<SignupWithOpenIDDto, 'clientId'>): Promise<SignupUserResultDto> {
        const result = await firstValueFrom(
            this.authService.signUpWithOpenID({ ...data, clientId: this.config.clientId! }),
        );

        await this.storeOobCodeData({
            url: data.url,
            email: data.email,
            length: result.codeLength,
            clientId: this.config.clientId!,
            requestType: OobRequestType.EMAIL_SIGNIN,
            id: result.id,
        });
        return result;
    }

    async resetPassword(data: Omit<ResetPasswordDto, 'clientId'>): Promise<void> {
        this.signInData = { email: data.email, password: data.password };
        return firstValueFrom(this.authService.resetPassword({ ...data, clientId: this.config.clientId! }));
    }

    refreshToken(data: Oauth2CreateTokenDto): Observable<AuthSession> {
        if (this.isRefreshingAccessToken) {
            console.debug('Waiting for access token to be refreshed...');
            return this.refreshTokenObservable!;
        }
        console.debug('Refreshing access token');
        this.isRefreshingAccessToken = true;
        this.refreshTokenObservable = this.authService.createToken(data).pipe(
            tap(() => (this.isRefreshingAccessToken = false)),
            switchMap((result) => this.storeSession(result)),
            shareReplay(1),
        );
        return this.refreshTokenObservable;
    }

    async logout() {
        const session = await this.getSessionFromStorage();
        if (session) {
            return this.revokeToken({ client_id: session.clientId, token: session.refreshToken });
        } else {
            await this.deleteSessionFromStorage();
            return null;
        }
    }

    async revokeToken(data: Oauth2RevokeTokenDto) {
        try {
            await firstValueFrom(this.authService.revokeToken(data));
            return this.deleteSessionFromStorage();
        } catch (e) {
            console.error(e);
            return of(null);
        }
    }

    async getOobCodeData(): Promise<OobCodeData | null> {
        const data = await this.storage.getItem(`${this.getStoragePrefix()}.oobCodeData`);
        return this.tryParse(data);
    }

    async storeSession(data: { accessToken: string; refreshToken: string }): Promise<DecodedAuthSession> {
        await this.storage.setItem(`${this.getStoragePrefix()}.session`, JSON.stringify(data));
        return {
            clientId: this.config.clientId!,
            refreshToken: data.refreshToken,
            accessToken: data.accessToken,
            decodedAccessToken: this.decodeAccessTokenJwt(data.accessToken)!,
        };
    }

    async createIcpToken(state: string, code: string): Promise<AuthSession> {
        const codeVerifier = await this.storage.getItem(`${this.getStoragePrefix()}.codeVerifier`);
        const params: AuthorizeParams = JSON.parse(
            (await this.storage.getItem(`${this.getStoragePrefix()}.authorizeParams`))!,
        );
        if (state !== params.state || !params.redirect_uri) {
            console.error('State does not match', { queryParams: { state, code }, params });
            throw new Error('State does not match');
        }
        const body: Oauth2CreateTokenDto = {
            grant_type: IdpOauth2GrantType.AUTHORIZATION_CODE,
            code,
            redirect_uri: params.redirect_uri,
            client_id: this.config.clientId!,
            code_verifier: codeVerifier!,
        };

        return await firstValueFrom(
            this.authService.createToken(body).pipe(
                switchMap((response) => {
                    const { refreshToken, accessToken } = response;
                    if ('idToken' in response) {
                        const parsedToken = JSON.parse(atob(response.idToken.split('.')[1]));
                        if (parsedToken.nonce !== params.nonce) {
                            throw new Error('nonce does not match');
                        }
                    }
                    return this.storeSession({ refreshToken, accessToken });
                }),
            ),
        );
    }

    async createAcmToken(state: string, code: string): Promise<OpenIdTokenResultDto> {
        const codeVerifier = await this.storage.getItem(`${this.getStoragePrefix()}.codeVerifier`);
        const payload: OpenIdTokenDto = { state, code, codeVerifier: codeVerifier! };
        return firstValueFrom(this.authService.createAcmToken(payload));
    }

    async deleteSessionFromStorage() {
        await this.storage.removeItem(`${this.getStoragePrefix()}.session`);
    }

    async isSignedIn() {
        return (await this.getSessionFromStorage()) !== null;
    }

    private async getSessionFromStorage(): Promise<DecodedAuthSession | null> {
        const tokens = await this.storage.getItem(`${this.getStoragePrefix()}.session`);
        const parsed = this.tryParse(tokens);
        return parsed
            ? {
                  ...parsed,
                  clientId: this.config.clientId,
                  decodedAccessToken: this.decodeAccessTokenJwt(parsed.accessToken),
              }
            : parsed;
    }

    private async storeOobCodeData(data: OobCodeData) {
        await this.storage.setItem(`${this.getStoragePrefix()}.oobCodeData`, JSON.stringify(data));
    }

    private async deleteOobCodeDataFromStorage() {
        return this.storage.removeItem(`${this.getStoragePrefix()}.oobCodeData`);
    }

    private getStoragePrefix() {
        return `icpIdp.${this.config.clientId}`;
    }

    private decodeAccessTokenJwt(accessToken: string): AccessToken {
        return JSON.parse(atob(accessToken.split('.')[1]).toString());
    }

    private tryParse(data: string | null) {
        if (data === null) {
            return data;
        }
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    }

    async authorize(payload: {
        idpUrl: string;
        scope: string;
        redirectUri: string;
        action?: IdpOauth2AuthorizeAction;
        login_hint?: string;
    }) {
        const nonce = randomString(36);
        const state = randomString(36);
        const codeVerifier = randomString(32);
        const challenge = getCodeChallenge(codeVerifier);
        await this.storage.setItem(`${this.getStoragePrefix()}.codeVerifier`, codeVerifier);
        const params: Oauth2AuthorizeQueryDto = {
            response_type: IdpOauth2LoginResponseType.CODE,
            code_challenge: challenge,
            code_challenge_method: IdpOauth2LoginCodeChallengeMethod.S256,
            client_id: this.config.clientId!,
            redirect_uri: payload.redirectUri,
            nonce,
            state,
            scope: IdpOauth2LoginScope.OPENID,
        };
        if (payload.action) {
            params.action = payload.action;
        }
        if (payload.login_hint) {
            params.login_hint = payload.login_hint;
        }
        const httpParams = new HttpParams({
            fromObject: params as unknown as { [param: string]: string },
        });
        const authorizeParams: AuthorizeParams = {
            state,
            nonce,
            redirect_uri: payload.redirectUri,
        };
        await this.storage.setItem(`${this.getStoragePrefix()}.authorizeParams`, JSON.stringify(authorizeParams));
        return `${payload.idpUrl}/idp/oauth2/v1/authorize?${httpParams}`;
    }

    /**
     * Redirects to the ACM login page. The user will be redirected back to the app after the login has succeeded.
     */
    async authorizeAcm() {
        const codeVerifier = randomString(32);
        const params: OpenIdAuthorizeQueryDto = {
            codeChallenge: getCodeChallenge(codeVerifier),
        };
        const query = new HttpParams({
            fromObject: params as unknown as { [key: string]: string },
        });
        await this.storage.setItem(`${this.getStoragePrefix()}.codeVerifier`, codeVerifier);
        return `${this.config.serverUrl}/idp/v1/openid/acm/authorize?${query}`;
    }

    listOpenIDProviders() {
        return this.getAuthorizationHeader().pipe(
            switchMap((headers) =>
                this.http.get<GetOpenidProvidersDto[]>(`${this.config.serverUrl}/idp/v1/users/openid-providers`, {
                    headers,
                }),
            ),
        );
    }

    linkOpenId(provider: OpenIdProvider, payload: LinkOpenIDDto) {
        return this.getAuthorizationHeader().pipe(
            switchMap((headers) =>
                this.http.post<GetOpenidProvidersDto>(
                    `${this.config.serverUrl}/idp/v1/users/openid-providers/${provider}`,
                    payload,
                    { headers },
                ),
            ),
        );
    }

    unlinkOpenId(provider: OpenIdProvider, sub: string) {
        return this.getAuthorizationHeader().pipe(
            switchMap((headers) =>
                this.http.delete<void>(`${this.config.serverUrl}/idp/v1/users/openid-providers/${provider}/${sub}`, {
                    headers,
                }),
            ),
        );
    }

    private getAuthorizationHeader() {
        return this.getCurrentSession().pipe(
            map((session) => {
                return new HttpHeaders(
                    session?.accessToken ? { [HttpHeaderNames.AUTHORIZATION]: `Bearer ${session?.accessToken}` } : {},
                );
            }),
        );
    }
}
