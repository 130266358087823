import { Sha256 } from '@aws-crypto/sha256-js';
/**
 * This function must match getCodeChallenge on the server
 */
export function getCodeChallenge(codeVerifier: string): string {
    // crypto.subtle is not available in non-secure contexts (http).
    // Since the capacitor app runs on http, we need a library for it
    const hash = new Sha256();
    hash.update(codeVerifier);
    const hashArray = Array.from(hash.digestSync());
    const str = hashArray.map((bytes) => String.fromCharCode(bytes)).join('');
    return btoa(str).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export function randomString(length: number): string {
    return [...crypto.getRandomValues(new Uint8Array(length))]
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
}
